export const PAYMENT_METHODS = [
    {
        src: 'footer/payment_methods/mastercard.svg',
        title: __('Master Card'),
        available_in: [
            'uk',
            'eu',
            'us',
            'au',
            'ca',
        ],
    },
    {
        src: 'footer/payment_methods/maestro.svg',
        title: __('Maestro'),
        available_in: [
            'uk',
            'eu',
            'us',
            'au',
            'ca',
        ],
    },
    {
        src: 'footer/payment_methods/visa.svg',
        title: __('Visa'),
        available_in: [
            'uk',
            'eu',
            'us',
            'au',
            'ca',
        ],
    },
    {
        src: 'footer/payment_methods/amex.svg',
        title: __('American Express'),
        available_in: [
            'uk',
            'eu',
            'us',
            'au',
            'ca',
            'no',
            'ch',
        ],
    },
    {
        src: 'footer/payment_methods/apple.svg',
        title: __('Apple Pay'),
        available_in: [
            'uk',
            'eu',
            'us',
            'au',
            'ca',
            'no',
            'ch',
        ],
    },
    {
        src: 'footer/payment_methods/gpay.svg',
        title: __('Google Pay'),
        available_in: [
            'uk',
            'eu',
            'us',
            'au',
            'ca',
            'no',
            'ch',
        ],
    },
    {
        src: 'footer/payment_methods/clearpay.svg',
        title: __('Clearpay'),
        available_in: [
            'uk',
        ],
    },
    {
        src: 'footer/payment_methods/klarna.svg',
        title: __('Klarna'),
        available_in: [
            'uk',
            'eu',
            'us',
            'au',
            'ca',
            'no',
            'ch',
        ],
    },
    {
        src: 'footer/payment_methods/amazon.svg',
        title: __('Amazon Pay'),
        available_in: [
            'us',
        ],
    },
    {
        src: 'footer/payment_methods/paypal.svg',
        title: __('PayPal'),
        available_in: [
            'uk',
            'eu',
            'au',
            'ca',
            'no',
            'ch',
        ],
    },
];
