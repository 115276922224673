/* eslint-disable no-useless-escape */
/* eslint-disable fp/no-let */
/* eslint-disable max-len */
// Constants and type definitions
// @ts-nocheck

export const SITES = {
    us: '/us/',
    uk: '/',
    row: '/row/',
    au: '/au/', // Australia
    no: '/no/', // Norway
    ca: '/ca/', // Canada
    ch: '/ch/', // Switzerland
    be: '/eu/', // Belgium
    de: '/eu/', // Germany
    ie: '/eu/', // Ireland
    es: '/eu/', // Spain
    fr: '/eu/', // France
    it: '/eu/', // Italy
    lu: '/eu/', // Luxembourg
    nl: '/eu/', // Netherlands
    at: '/eu/', // Austria
    pt: '/eu/', // Portugal
    fi: '/eu/', // Finland
    gr: '/eu/', // Greece
    si: '/eu/', // Slovenia
    hr: '/eu/', // Croatia
    ee: '/eu/', // Estonia
    lv: '/eu/', // Latvia
    sk: '/eu/', // Slovakia
    sm: '/eu/', // San Marino
    mc: '/eu/', // Monaco
    bg: '/eu/', // Bulgaria
    dk: '/eu/', // Denmark
    hu: '/eu/', // Hungary
    pl: '/eu/', // Poland
    ro: '/eu/', // Romania
    se: '/eu/', // Sweden
    eu: '/eu/', // Set a generic EU
};

type CountryCode = keyof typeof SITES; // Define a type for country codes

export const DEFAULT_COUNTRY_CODE: CountryCode = 'uk';

// This function sets the selected country, modifies the current URL, and stores the choice in localStorage
/** @namespace Inov8/Util/CountryChangeUtility/changeCountryCode */
export function changeCountryCode(newCountryCode) {
    if (!Object.keys(SITES).includes(newCountryCode)) {
        return;
    }

    // Get the URL path from the SITES mapping
    const selectedCountryPath = SITES[newCountryCode];

    // Store the selected country's URL path in localStorage after removing the slashes
    localStorage.setItem('selectedCountry', selectedCountryPath.replace(/\//g, ''));

    // Create a URL object from the current location
    const currentUrl = new URL(window.location.href);

    // Save the current query string
    const queryString = currentUrl.search;

    // Hardcoded array of all possible country path segments to save pain right now
    const countryPaths = ['/us/', '/row/', '/au/', '/no/', '/ca/', '/ch/', '/eu/'];

    // Split the current path into segments
    const pathSegments = currentUrl.pathname.split('/').filter((segment) => segment.length > 0);

    // Remove the first segment if it matches any of the country paths
    if (countryPaths.includes(`/${pathSegments[0]}/`)) {
        pathSegments.shift();
    }

    // Add the new country path, adjusting for the UK which has root path '/'
    if (newCountryCode === 'uk') {
        // For UK, the path should be the root '/' only if there are no additional segments
        pathSegments.unshift('');
    } else {
        // Add the new country path segments without slashes
        pathSegments.unshift(selectedCountryPath); // Remove leading and trailing slashes
    }

    // Construct the new path
    let newPath = `/${pathSegments.join('/')}`;

    // Ensure there are no double slashes in the new path
    newPath = newPath.replace(/\/\/+/g, '/');

    // Adjust the final URL to ensure it has a trailing slash if the original path had one, except for the UK root path
    const finalUrl = currentUrl.origin + newPath + queryString;

    // Redirect to the new URL without the query string
    window.location.href = finalUrl;
}

// Function triggered by dropdowns in header and foot and popup components to change the shipping country
/** @namespace Inov8/Util/CountryChangeUtility/changeShippingTo */
export function changeShippingTo(country: { label?: string; value?: string }) {
    if (country && country.value) {
        changeCountryCode(country.value);
    }
}
/** @namespace Inov8/Util/CountryChangeUtility/getCurrentCountryCodeFromURL */
export function getCurrentCountryCodeFromURL() {
    const countryPaths = ['/us/', '/row/', '/au/', '/no/', '/ca/', '/ch/', '/eu/'];
    // Extract the country codes from the countryPaths array and join them into a regex pattern
    const countryCodes = countryPaths.map((path) => path.replace(/\//g, '')); // Remove slashes from each path
    const regex = new RegExp(`^/(${countryCodes.join('|')})(?=/|$)`);

    const matches = window.location.pathname.match(regex);

    return matches ? matches[1] : DEFAULT_COUNTRY_CODE;
}
